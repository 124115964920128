

import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { usePlayerSearchStore } from "../PlayerSearch/Store/PlayerSearchStore";
import { useAuthStore } from "../Auth/store/Auth";

import Logo from "../assets/image/logowhite.webp";

const Footer = observer(() => {
    const {
        authapproved
      } = useAuthStore();
      const {
        reset, 
      } = usePlayerSearchStore();

      const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      };
    return(
        <footer id="footer" className=" bg-[#282B2D]">
            <div className="grid md:grid-cols-4 grid-cols-2 md:gap-x-11 2xl:px-28 lg:px-20 md:px-10 px-4 md:py-24 py-10">
                <div className=" md:col-span-1 col-span-2 text-[#fff] md:text-base text-sm font-medium font-poppins">
                    <img alt="logo" className="max-w-[185px] md:w-auto w-[156.42px]" src={Logo}/>
                    {/* <p className="mt-3">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy</p> */}
                </div>
                <div className="text-[#fff]">
                    <p className=" md:text-2xl text-base font-bold md:mb-5 mb-3 md:mt-0 mt-5">Our Links</p>
                    <ul className=" md:text-base text-sm leading-10">
                        <li>
                            <Link onClick={()=>handleScrollToTop()} to="/">Home</Link>
                        </li>
                        {!authapproved
                        ?
                        <>
                        <li>
                            <Link  onClick={()=>handleScrollToTop()}  to="/aboutus">About Us</Link>
                        </li>
                        <li>
                            <Link  onClick={()=>handleScrollToTop()}  to="/gettheapp">Get The APP</Link>
                        </li>
                        <li>
                            <Link  onClick={()=>handleScrollToTop()}  to="/contactus">Contact us</Link>
                        </li>
                        </>
                        :
                        <>
                        <li>
                            <Link  onClick={()=>handleScrollToTop()}  to="/league">Upcoming Leagues</Link>
                        </li>
                        <li>
                            <Link  onClick={()=>handleScrollToTop()}  to="/matchpage">My Calendar</Link>
                        </li>
                        <li>
                            <Link  onClick={()=>{handleScrollToTop(); reset();}}  to="/playersearch">Player Search</Link>
                        </li>
                        </>
                        }
                        
                    </ul>
                </div>
                <div className="text-[#fff]">
                    <p className=" md:text-2xl text-base font-bold md:mb-5 mb-3 md:mt-0 mt-5 opacity-0 invisible">Our Links</p>
                    <ul className=" md:text-base text-sm leading-10">

                        {!authapproved &&
                        <li>
                            <Link onClick={()=>handleScrollToTop()} to="/login">Signup</Link>
                        </li>
                        }
                        {authapproved &&
                         <li>
                            <Link onClick={()=>handleScrollToTop()} to="/gettheapp">Get The APP</Link>
                        </li>
                        }
                        <li>
                            <Link onClick={()=>handleScrollToTop()} to="/terms-condition">Terms and Conditions</Link>
                        </li>
                        <li>
                            <Link onClick={()=>handleScrollToTop()} to="/privacy-policy">Privacy Policy</Link>
                        </li>
                        {authapproved &&
                         <li>
                            <Link onClick={()=>handleScrollToTop()} to="/contactus">Contact us</Link>
                        </li>
                        }
                        
                    </ul>
                </div>
                {/* <div className="text-[#fff] md:col-span-1 col-span-2">
                    <p className=" md:text-2xl text-base font-bold md:mb-5 mb-3 md:mt-0 mt-5">Find Us</p>
                    <ul className=" md:text-lg text-base leading-10">
                        <li className=" flex items-center">
                            <svg className="mr-4" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                <path d="M20.275 21.5C17.7667 21.5 15.3217 20.9458 12.9402 19.8375C10.5578 18.7292 8.44811 17.2514 6.611 15.4042C4.77311 13.5569 3.29533 11.4422 2.17767 9.05983C1.05922 6.67828 0.5 4.23333 0.5 1.725V1.1125C0.5 0.898611 0.519444 0.694444 0.558333 0.5H7.38333L8.4625 6.3625L5.1375 9.71667C5.95417 11.1167 6.98006 12.4292 8.21517 13.6542C9.4495 14.8792 10.8056 15.9389 12.2833 16.8333L15.6667 13.45L21.5 14.6167V21.4417C21.3056 21.4611 21.1014 21.4759 20.8875 21.486C20.6736 21.4953 20.4694 21.5 20.275 21.5Z" fill="#39FD13"/>
                            </svg>
                            <Link to="/">+91 000 0000 000</Link>
                        </li>
                        <li className=" flex items-center">
                            <svg className="mr-4" xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20" fill="none">
                                <path d="M2.66683 19.3337C2.02516 19.3337 1.47605 19.1054 1.0195 18.6488C0.562163 18.1915 0.333496 17.642 0.333496 17.0003V3.00033C0.333496 2.35866 0.562163 1.80955 1.0195 1.35299C1.47605 0.895659 2.02516 0.666992 2.66683 0.666992H21.3335C21.9752 0.666992 22.5247 0.895659 22.982 1.35299C23.4386 1.80955 23.6668 2.35866 23.6668 3.00033V17.0003C23.6668 17.642 23.4386 18.1915 22.982 18.6488C22.5247 19.1054 21.9752 19.3337 21.3335 19.3337H2.66683ZM21.3335 5.33366L12.6127 10.7878C12.5154 10.8462 12.4132 10.8897 12.3058 10.9185C12.1993 10.948 12.0974 10.9628 12.0002 10.9628C11.9029 10.9628 11.8011 10.948 11.6945 10.9185C11.5872 10.8897 11.4849 10.8462 11.3877 10.7878L2.66683 5.33366V17.0003H21.3335V5.33366ZM12.0002 8.83366L21.3335 3.00033H2.66683L12.0002 8.83366ZM2.66683 5.33366V5.62533V3.90449V3.93366V3.00033V3.93366V3.91849V5.62533V5.33366V17.0003V5.33366Z" fill="#39FD13"/>
                            </svg>
                            <Link to="/">tennis123@.com</Link>
                        </li>
                    </ul>
                </div> */}
            </div>
            <div className="bg-[#343333] text-center text-[#fff] text-sm py-3 font-medium">
                <p>© {new Date().getFullYear()} www.flexleaguetennis.com. All rights reserved.</p>
            </div>
        </footer>
    )
})
export default Footer;