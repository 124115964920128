/* eslint-disable jsx-a11y/alt-text */
import { observer } from "mobx-react";
import { useForm, Controller } from "react-hook-form";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import profilebg from "../assets/image/profilebg.webp";
import myaccount from "../assets/image/bgleague.webp";
import myprofile from "../assets/image/placeholder.webp";
import femaleprofile from "../assets/image/female.webp";
import { useEffect, useState } from "react";
import { RxEyeClosed, RxEyeOpen } from "react-icons/rx";
import { FaAngleLeft } from "react-icons/fa";
import Input, { isValidPhoneNumber } from "react-phone-number-input/input";
import React from "react";
import {
  PlayerSearchStore,
  usePlayerSearchStore,
  PlayerSearchStoreContext,
  
} from "./Store/PlayerSearchStore";
import "reactjs-popup/dist/index.css";
import { useAuthStore } from "../Auth/store/Auth";
import moment from "moment";
import Moment from "react-moment";
import { useMyMatchStore } from "../MyMatch/Store/MyMatchStore";
import { MyMatchPageStore, useMyMatchPageStore } from "../MatchPage/store/MyMatchPageStore";

// const PlayerDetail = observer(() => {
//   const { id } = useParams();
//   return (
//     <PlayerSearchStoreContext.Provider
//       value={
//         new PlayerSearchStore({
//           loadeditcitybol: false,
//           editloading: true,
//           id: id,
//         })
//       }
//     >
//       <Screen />
//     </PlayerSearchStoreContext.Provider>
//   );
// });
// export default PlayerDetail;

const PlayerDetail = observer(() => {
  const navigate = useNavigate();
  const { setMatchHistoryOfUser,setRoute } = useMyMatchStore();
  const { setRoutes,route } = useMyMatchPageStore();
  const { authapproved, isRedirect, redirectURL, RedirectChanged } =
    useAuthStore();
  useEffect(() => {
    if (!authapproved) {
      navigate("/");
    }
  }, [authapproved]);
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState("Season");
  const [division, setDivision] = useState(true);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { gettoken, getplayerdetail, gettablehide, playerDetails } =
    usePlayerSearchStore();
  const {
    getSeasonYear,
    getSeasons,
    getseasondropdown,
    getMatchDetails,
    seasons,
    seasondropdown,
    SeasonYear,
    gethistorydetail,
    noSeasonList,
    gethistory,
    SeasonMatch,
    nolistHistory,
  } = useAuthStore();
  useEffect(() => {
    gettoken(localStorage.getItem("token"));
    getplayerdetail(id);
    getseasondropdown();
  }, []);
  useEffect(() => {
    gethistorydetail(id, SeasonYear);
  }, [SeasonYear]);
  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: "smooth" });
  
  },[])
  const location = useLocation();
  const pattern = /^\/\w+/;
  const result = location.pathname.match(pattern);
  return (
    <>
      <div className="relative py-5 h-full flex items-center z-1">
        <img
        alt=""
          className="absolute h-full z-0 top-0 left-0 w-full object-cover object-top brightness-50"
          src={myaccount}
        />
        <div className="w-full 2xl:px-28 lg:px-20 md:px-10 px-4  relative z-1">
          <div className="flex flex-wrap justify-center ">
            <div className="2xl:w-[80%] border   border-[#ffffff] rounded lg:w-[80%] md:w-[90%] w-full 2xl:max-w-[90%]">
              <div className=" bg-[#6C6C6C] bg-opacity-40  rounded-lg lg:px-10 md:px-5 px-4  py-10">
                <div className="md:grid md:grid-cols-3 items-center gap-4">
                  <div className=" ">
                    <img
                    alt=""
                      crossOrigin="anonymous"
                      className="rounded-lg overflow-hidden w-full"
                      // src={playerDetails?.user?.image ?? myprofile}
                      src={BASE_URL + "/image/" + playerDetails?.user?.image}
                    />
                  </div>

                  <div className="relative md:ml-16 col-span-2 md:mt-0 mt-8">
                    <p className=" text-[#fff] font-normal md:text-5xl text-3xl  relative uppercase font-nunito">
                      {playerDetails?.user?.firstName}{" "}
                      {playerDetails?.user?.lastName}
                    </p>
                    <div className="flex overflow-auto ">
                      <div className="box-border rounded-sm flex flex-col mt-5 justify-center items-center  border-[#ffffff] bg-[#fff] h-16 w-20 xl:p-3 border-4 ">
                        <p className=" text-[#7E7777] font-medium md:text-2xl text-xl  relative">
                          {playerDetails?.total_leagues ?? "0"}
                        </p>
                        <p className=" text-[#7E7777] font-light text-[13px]  ">Leagues</p>
                      </div>
                      <div className="box-border rounded-sm ml-3 flex flex-col mt-5 justify-center items-center  border-[#39FD13] bg-[#39FD13] h-16 w-20 xl:p-3 border-4 ">
                        <p className=" text-[#7E7777] font-medium md:text-2xl text-xl  relative">
                          {playerDetails?.total_matches ?? "0"}
                        </p>
                        <p className=" text-[#7E7777] text-[13px] font-light">Matches</p>
                      </div>
                      <div className="box-border rounded-sm ml-3 flex flex-col mt-5 justify-center items-center  border-[#ffffff] bg-[#fff] h-16 w-20 xl:p-3 border-4 ">
                        <p className=" text-[#7E7777] font-medium md:text-2xl text-xl  relative">
                          {playerDetails.user?.level?.name.toFixed(1) ?? "0"}
                        </p>
                        <p className=" text-[#7E7777] text-[13px] font-light">Singles</p>
                      </div>
                      <div className="box-border rounded-sm ml-3 flex flex-col mt-5 justify-center items-center  border-[#ffffff] bg-[#fff] h-16 w-20 xl:p-3 border-4 ">
                        <p className=" text-[#7E7777] font-medium md:text-2xl text-xl  relative">
                          {playerDetails.user?.mixed_level?.name.toFixed(1) ?? "0"}
                        </p>
                        <p className=" text-[#7E7777] text-[13px] font-light">Mixed</p>
                      </div>
                      <div className="box-border rounded-sm ml-3 flex flex-col mt-5 justify-center items-center  border-[#ffffff] bg-[#fff] h-16 w-20 xl:p-3 border-4 ">
                        <p className=" text-[#7E7777] font-medium md:text-2xl text-xl  relative">
                          {playerDetails.user?.doubles_level?.name.toFixed(1) ?? "0"}
                        </p>
                        <p className=" text-[#7E7777] text-[13px] font-light">Doubles</p>
                      </div>
                    </div>
                    {/* <div className="flex">
                      <div className="box-border flex flex-col mt-5 justify-center items-center  border-[#ffffff] bg-[#fff] h-20 w-32 xl:p-10 border-4 ">
                        <p className=" text-[#7E7777] font-medium md:text-3xl text-2xl  relative">
                          {playerDetails?.user?.level?.name.toFixed(1) ?? "0"}
                        </p>
                        <p className=" text-[#7E7777] font-light  ">Level</p>
                      </div>

                      <div className="box-border ml-3 flex flex-col mt-5 justify-center items-center  border-[#ffffff] bg-[#fff] h-20 w-32 xl:p-10 border-4 ">
                        <p className=" text-[#7E7777] font-medium md:text-3xl text-2xl  relative">
                          {playerDetails?.total_leagues ?? "0"}
                        </p>
                        <p className=" text-[#7E7777] font-light  ">Seasons</p>
                      </div>
                      <div className="box-border ml-3 flex flex-col mt-5 justify-center items-center  border-[#39FD13] bg-[#39FD13] h-20 w-32 xl:p-10 border-4 ">
                        <p className=" text-[#7E7777] font-medium md:text-3xl text-2xl  relative">
                          {playerDetails?.total_matches ?? "0"}
                        </p>
                        <p className=" text-[#7E7777] font-light  ">Matches</p>
                      </div>
                    </div> */}

                    <div className=" mb-3">
                      <p className="flex whitespace-nowrap items-stretch  justify-between text-[#ffffff] font-medium mt-7 ">
                        City
                        <span className="border-t self-end mb-2 w-full border-dashed"></span>
                        {playerDetails?.user?.city?.city_name ?? ""}
                      </p>

                      {/* <p className="flex  items-stretch  justify-between  text-[#ffffff] font-medium mt-7 ">Home Court<div className="border-t self-end w-full border-dashed"></div>{homeCourt}</p>
                          <p className="flex items-stretch  justify-between  text-[#ffffff] font-medium mt-7 ">Current ranking<div className="border-t self-end w-full border-dashed"></div>40%</p>
                          <p className="flex items-stretch  justify-between text-[#ffffff] font-medium mt-7 ">Career win/loss<div className="border-t self-end  w-full border-dashed"></div>40%</p>
                           */}
                      <p className="flex whitespace-nowrap items-stretch  justify-between text-[#ffffff] font-medium mt-7 ">
                        Home Court
                        <span className="border-t self-end mb-2 w-full border-dashed"></span>
                        {playerDetails?.user?.homeCourt?.displayName ?? ""}
                      </p>
                      {/* <p className="flex whitespace-nowrap items-stretch  justify-between text-[#ffffff] font-medium mt-7 ">
                        Current Ranking
                        <span className="border-t self-end mb-2 w-full border-dashed"></span>
                        {playerDetails?.current_ranking ?? "0"}%
                      </p> */}
                      {/* <p className="flex whitespace-nowrap items-stretch  justify-between text-[#ffffff] font-medium mt-7 ">
                        Career win/loss
                        <span className="border-t self-end mb-2 w-full border-dashed"></span>
                        {career_win_loss}%
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap justify-center bg-[#161414]"  >
      <div className="2xl:w-[80%]   lg:w-[85%] md:w-[90%] w-full 2xl:max-w-[90%]  pt-5 pb-5 relative z-1">
        {route == "/home" ?
         <Link
         to="/"
         onClick={()=>setRoutes("")}
         className=" flex justify-end"
       >
         <p className="font-bold text-[#fff]  text-2xl flex items-center ">
           <FaAngleLeft /> Back to Home
         </p>
       </Link>
       :
         (isRedirect ? (
          <Link
            to={redirectURL}
            onClick={() => {
              gettablehide(true);
            }}
            className=" flex justify-end"
          >
            <p className="font-bold text-[#fff] text-2xl flex items-center ">
              <FaAngleLeft /> Back to Matches
            </p>
          </Link>
        ) : (
          <Link
            to="/playersearch"
            onClick={() => {
              RedirectChanged("/playersearch", false);
              gettablehide(true);
            }}
            className=" flex justify-end"
          >
            <p className="font-bold text-[#fff] text-2xl flex items-center ">
              <FaAngleLeft /> Back to Players
            </p>
          </Link>
        ))
        }
       

        <div className=" bg-[#000] grid-cols-3 p-3 border-2 border-[#55ed5a]">
          {/* <div className=" flex items-center  justify-end   relative z-10">
            <div
              onClick={() => {
                setDivision(true);
                // setQutarFinal(false);
                setActiveTab("Season");
              }}
              className={`box-border border-[1px]  cursor-pointer flex flex-col mt-5 border-r border-[gray] justify-center items-center  bg-[#fff] h-10 w-[6rem] text-[12px] xl:p-[.30rem]  ${
                activeTab === "Season" ? "bg-green" : ""
              }`}
            >
              <p className=" text-[#7E7777] font-medium md:text-3xl text-2xl  relative"></p>
              <p className=" text-[#7E7777] font-light ">Season</p>
            </div>

            <div
              onClick={() => {
                setDivision(false);
                getMatchDetails(id);
                setActiveTab("Matches");
              }}
              className={`box-border border-[1px]  cursor-pointer border-[gray]   flex flex-col mt-5  justify-center items-center  bg-[#fff] h-10 w-[6rem] text-[12px] xl:p-[.30rem]  ${
                activeTab === "Matches" ? "bg-green" : ""
              }`}
            >
             <button
                      onClick={() => {
                        getMatchDetails(id);
                      }}
                      className=" text-[#7E7777] font-light"
                    >
                      Matches
                    </button>
            </div>
          </div> */}

          {division === true ? (
            <label className="font-bold text-2xl text-[#fff]">Leagues History</label>
          ) : (
            <label className="font-bold text-2xl">Match History</label>
          )}
          <div className="flex justify-end">
            {division === true ? (
              <div className="w-2/12">
                 <select
  value={SeasonYear}
  onChange={(e) => {
    getSeasonYear(e.target.value);
  }}
  name="Year"
  id="Year"
  className="bg-[#f1f1f1] mt-3 text-[#837575] w-full border border-[#7E7777] rounded-lg px-3 focus:outline-none"
>
  {/* Previous year (2024) */}
  <option value={new Date().getFullYear() - 1}>
    {new Date().getFullYear() - 1}
  </option>

  {/* Current year (2025) */}
  <option value={new Date().getFullYear()}>
    {new Date().getFullYear()}
  </option>

  {/* Next year (2026) */}
  <option value={new Date().getFullYear() + 1}>
    {new Date().getFullYear() + 1}
  </option>
</select>
              </div>
            ) : (
              <div className="w-2/12">
                <select
                  value={seasons}
                  onClick={() => {
                    getMatchDetails(id, seasons);
                  }}
                  onChange={(e) => {
                    getSeasons(e.target.value);
                  }}
                  name="Seasons"
                  id="Seasons"
                  // required
                  className=" bg-[#f1f1f1] mt-3 text-[#837575] w-full border border-[#7E7777] rounded-lg px-3 focus:outline-none"
                >
                  <option value="">All Seasons</option>
                  {seasondropdown.map((data, index) => (
                    <option value={data?._id} key={index}>
                      {data?.name + "(" + data?.year + ")"}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>

          {division === true ? (
            <div
              id="style-1"
              className=" bg-[#000] border-2 border-[#fff] rounded mt-3  h-[430px] "
            >
              <div className="overflow-hidden">
                <table className="w-full text-[#fff]">
                  <thead>
                    <tr className="bg-green text-[#000]">
                      <th className="py-3">#</th>
                      <th className="text-left py-3">League</th>
                      <th className="text-left py-3">Division</th>
                      <th className="text-left " >Level</th>
                      <th className="text-left " >Win %</th>
                      <th className="text-left ">Loss %</th>
                    </tr>
                  </thead>
                  <tbody className="text-center text-[#fff]">
                    {gethistory.map((data, index) => (
                      <tr className="hover:bg-[#39fd13] hover:bg-opacity-10" key={index}>
                        <td>{index + 1}</td>
                        <td className="py-3 text-left  capitalize">
                          <Link
                            onClick={(e) => {
                              setRoute(result);
                            }}
                            className="underline cursor-pointer hover:text-green"
                            to={`/matchhistory/${id}/${data?._id}/${data?.league?.name}/${playerDetails?.user?.firstName + " " + playerDetails?.user?.lastName}/${data?.league?.league_type}`}
                            //   navigate(`/matchhistory/${res?._id}/${id}/${leaguesiddata}/${res?.firstName}`);
                          >
                            {data?.league?.name
                              ?.replace("(Female)", "")
                              ?.replace("(Male)", "") ?? "N/A"}
                          </Link>
                        </td>
                        <td className="text-left "  >{data?.division?.code ?? "N/A"}</td>
                        <td className="text-left " >{data?.level?.name.toFixed(1) ?? "N/A"}</td>
                        <td className="text-left " >{Math.trunc(data?.win) ?? "0"}%</td>
                        <td className="text-left " >{Math.trunc(data?.loss) ?? "0"}%</td>
                      </tr>
                    ))}

                    {nolistHistory && (
                      <tr>
                        <td colSpan={6} className="py-20 ">
                          <p className=" text-center capitalize font-semibold text-3xl">
                            {playerDetails?.user?.firstName}{" "}
                            {playerDetails?.user?.lastName} Has Not Played Any
                            Leagues Yet.
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {/* <div className="text-[#3636364d] flex justify-center mt-40 text-3xl ">
                    You have not played any season yet
                  </div> */}
            </div>
          ) : (
            <div
              id="style-1"
              style={{ height: "430px", overflow: "auto" }}
              className=" bg-[#ffffff] border-2  border-[#fff] rounded mt-3  h-[430px] "
            >
              <div className="rounded-t-lg flex overflow-hidden">
                <table className="w-full  text-[#fff]">
                  <thead className="sticky">
                    <tr className="bg-[#282B2D] top-0  ">
                      <th className="py-5">#</th>
                      <th className="py-5">League</th>
                      <th className="md:py-5">Opponent</th>
                      <th className="md:py-5">Mate</th>
                      {/* {leaguetype != "singles" ? (
                            <th className="text-left">Partner</th>
                          ) : (
                            ""
                          )} */}
                      <th>Played On</th>
                      <th>Home Court</th>
                      <th>Result</th>
                      {/* <th>Ranking</th> */}
                    </tr>
                  </thead>

                  <tbody className="text-center text-[#000]">
                    {/* {nolistHistory && ( */}
                    {SeasonMatch.map((res, index) => (
                      <tr
                        key={index}
                        className="hover:bg-[#39fd13] hover:bg-opacity-5 "
                      >
                        <td>{index + 1}</td>
                        <td className="capitalize">
                          {res?.league?.name
                            ?.replace("(Female)", "")
                            ?.replace("(Male)", "") ?? "N/A"}
                        </td>
                        <td className="text-sm  py-3">
                          {res?.opponent.map((playername, index) => (
                            <span
                              title="View Profile"
                              key={index}
                              onClick={(e) => {
                                // RedirectChanged(`/mymatch/${id}`, true);
                                // navigate(
                                //   `/playerdetail/${playername?._id}`
                                // );
                              }}
                              className="capitalize cursor-auto "
                            >
                              {(index ? ", " : "") + playername?.firstName}{" "}
                              {playername?.lastName}
                            </span>
                          ))}
                          {/* {res.opponent.firstName} {res.opponent.lastName} */}
                        </td>
                        <td className="text-sm  py-3">
                          {res?.mate == undefined
                            ? "-"
                            : res?.mate?.firstName + " " + res?.mate?.lastName}

                          {/* {res.opponent.firstName} {res.opponent.lastName} */}
                        </td>

                        {/* {playername?._id != user?._id ? <>{playername.firstName} {playername.lastName},</>  :"" }  */}
                        {/* {leaguetype != "singles" ? ( */}
                        {/* <td>
                                <span
                                  title="View Profile"
                                  className="capitalize cursor-pointer  hover:text-green"
                                  onClick={(e) => {
                                    navigate(
                                      `/playerdetail/${res?.mate?._id}`
                                    );
                                  }}
                                >
                                  {res?.mate?.firstName} {res?.mate?.lastName}
                                </span>
                              </td> */}
                        {/* ) : (
                              ""
                            )} */}
                        <td className=" py-5 flex justify-center">
                          {" "}
                          {res?.schedule_date !== null ? (
                            <span className="flex" title="Schedule Dates">
                              <Moment
                                format={
                                  res?.schedule_date?.includes('May')
                                    ? "MMM DD"
                                    : "MMM. DD"
                                }
                              >
                                {res?.schedule_date}
                              </Moment>
                              <p className="px-1"> @ </p>
                              {moment(res?.schedule_time).format("hh:mm A")}
                            </span>
                          ) : (
                            "N/A"
                          )}{" "}
                        </td>

                        <td title="View Map" className="py-3">
                          {res?.home_court?.name}
                        </td>

                        <td>
                          {" "}
                          <p className="text-[black] text md:text-sm text-xs">
                            {res.match_result == "loss" ||
                            res.match_result == "win" ? (
                              <>
                                <span className="border border-green  2xl:mx-2 mx-[1px] lg:p-1">
                                  {res?.team_first_score?.set_one}-
                                  {res?.team_second_score?.set_one}
                                </span>
                                <span className="border border-green  2xl:mx-2 mx-[1px] lg:p-1">
                                  {res?.team_first_score?.set_two}-
                                  {res?.team_second_score?.set_two}
                                </span>
                                {res?.team_first_score?.set_three ||
                                res?.team_second_score?.set_three != null ? (
                                  <span className="border border-green 2xl:mx-2 mx-[1px] lg:p-1">
                                    {res?.team_first_score?.set_three}-
                                    {res?.team_second_score?.set_three}
                                  </span>
                                ) : (
                                  ""
                                )}
                                -{" "}
                                {res?.match_result === "loss" ? (
                                  <span className="text-[black]  2xl:mx-2 mx-[1px] lg:p-1">
                                    Lost
                                  </span>
                                ) : (
                                  <span className="text-[black]  2xl:mx-2 mx-[1px] lg:p-1">
                                    Won
                                  </span>
                                )}
                              </>
                            ) : res?.result === "score" ? (
                              <>
                                <span className="border border-green  2xl:mx-2 mx-[1px] lg:p-1">
                                  {res?.team_first_score?.set_one}-
                                  {res?.team_second_score?.set_one}
                                </span>
                                <span className="border border-green  2xl:mx-2 mx-[1px] lg:p-1">
                                  {res?.team_first_score?.set_two}-
                                  {res?.team_second_score?.set_two}
                                </span>
                                {res?.team_first_score?.set_three ||
                                res?.team_second_score?.set_three != null ? (
                                  <span className="border border-green 2xl:mx-2 mx-[1px] lg:p-1">
                                    {res?.team_first_score?.set_three}-
                                    {res?.team_second_score?.set_three}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : res?.is_score_update === false ? (
                              <span className="md:text-sm capitalize">-</span>
                            ) : (
                              <span className="md:text-sm capitalize">
                                {res?.result === "Forfieted" ? (
                                  <span>Forfeited By {res?.forfiet_by}</span>
                                ) : (
                                  <span>Withdrew By {res?.Withdraw_by}</span>
                                )}
                              </span>
                            )}
                          </p>
                        </td>
                      </tr>
                    ))}

                    {noSeasonList && (
                      <tr>
                        <td colSpan={6} className="py-20 ">
                          <p className=" text-center capitalize font-semibold text-3xl">
                            {playerDetails?.user?.firstName}{" "}
                            {playerDetails?.user?.lastName} Have Not Played Any
                            Matches Yet
                          </p>
                        </td>
                      </tr>
                    )}
                    {/* )} */}
                  </tbody>
                </table>
              </div>
              {/* <div className="text-[#3636364d] flex justify-center mt-40 text-3xl ">
                  You have not played any season yet
                </div> */}
            </div>
          )}
        </div>
      </div>
      </div>
    </>
  );
});

export default PlayerDetail;
