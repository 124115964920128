/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { observer } from "mobx-react";
import { useController, useForm } from "react-hook-form";
import Select from "react-select";


// import { format } from 'date-fns';
// import moment from "moment";
import { configure } from "mobx";
import { useNavigate, useParams } from "react-router-dom";
// import profilebg from "../assets/image/profilebg.webp";
// import myaccount from "../assets/image/bgleague.webp";
// import myprofile from "../assets/image/placeholder.webp";
// import femaleprofile from "../assets/image/female.webp";
import img1 from "../assets/image/img1.webp";
import img2 from "../assets/image/img2.webp";
// import img3 from "../assets/image/img3.webp";
import img4 from "../assets/image/img4.webp";
import img5 from "../assets/image/img5.webp";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { RxEyeClosed, RxEyeOpen } from "react-icons/rx";
import Input, { isValidPhoneNumber } from "react-phone-number-input/input";
import React from "react";
// import { PlayerSearchStore ,usePlayerSearchStore ,PlayerSearchStoreContext   } from "./Store/PlayerSearchStore";
import {
  PlayerSearchStore,
  usePlayerSearchStore,
  PlayerSearchStoreContext,
} from "../PlayerSearch/Store/PlayerSearchStore";
import { AuthStoreContext, useAuthStore, AuthStore } from "../Auth/store/Auth";
import { format } from "date-fns";
import { useMyMatchStore } from "../MyMatch/Store/MyMatchStore";
import moment from "moment";
import Moment from "react-moment";
import { FaEdit, FaPencilAlt,FaSave } from "react-icons/fa";
import Popup from "reactjs-popup";
import {
  Widget,
  addLinkSnippet,
  addResponseMessage,
  dropMessages,
} from "react-chat-widget";
import { useRegisterStore } from "../Register/store/RegisterStore";
import { toast } from "react-toastify";
configure({
  enforceActions: "never",
});
const MyAccount = observer(() => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { register, handleSubmit, watch, setValue } = useForm();
  

  const {
    authapproved,
    user,
    getProfile,
    loadseason,
    getuserdetail,
    updateProfileImage,
    myDetails,
    updateProfile,
    loginLoading,
    setdobuser,
    dobusererr,
    gethomecourtdropdown,
    homecourtdrop,
    gethistorydetail,
    gethistory,
    seasondropdown,
    nolistHistory,
    noSeasonList,
    getstatedropdown,
    getSeasonYear,
    getSeasons,
    getseasondropdown,
    getMatchDetails,
    SeasonMatch,
    SeasonYear,
    seasons,
    statedrop,
    loader,
    setSelectedImage,
    selectedImage,
    ImageCheck,
    setlatestuser,
    userlatest,
    setlatestuserapiii
  } = useAuthStore();
  const {
    getfirstname,
    getlastname,
    getcityid,
    gettablehide,
    clearsearechlist,
    ListTrue,
    reset,
  } = usePlayerSearchStore();
  const { getleaugesfalse, changeStep, allClear } = useRegisterStore();

  useEffect(() => {
    getProfile()
  },[loadseason])

  // const [latestuser,setlatestuser] = useState()

  useEffect(() => {
    setlatestuser(user)
  },[])

  const handleChange = (e) => {
    setlatestuserapiii(e)
    // const { name, value } = e.target;
    // setlatestuser((prevUser) => ({
    //   ...prevUser,
    //   [name]: value,
    // }));
  };

  const {
    levels,
    cityDropdown,
    UpdateLoading,
    homeCourtDropdown,
    getformcheck,
    getdetail,
    getcity,
    gethomecourt,
    getlevel,
    formcheck,
  } = usePlayerSearchStore();
  const {
    logout,
    setloginNoEmail,
    setupcomingleauge,
    getNotification,
    RedirectChanged,
    notificationcount,
    getAllNotification,
    deletepopup,
    setdeletepopup,
    deleteload,
    deleteUser,
    deletenav,
    

  } = useAuthStore();
  const {
    register: register3,
    handleSubmit: handleSubmit3,
    control,
    formState: { errors: errors3 },

  } = useForm(
    {
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      level: user?.level?._id,
      homeCourt: user?.homeCourt?._id,
      mobile: user?.mobile,
      dob: user?.dob,
      city: user?.city?._id,
      address_line1: user?.address_line1,
      address_line2: user?.address_line2,
      address_city: user?.address_city,
      address_zip: user?.address_zip,
      address_state: user?.address_state?._id,
      address_country: user?.address_country,
    },
  }
  );

  const {
    field: {
      value: HomeValue,
      onChange: homeCourtnameChange,
      ...restHome
    },
    fieldState: { errors: langError },
  } = useController({
    name: "homeCourt",
    control,
    rules: {
      required:  "This field is required",
    }
,
  });

  const { leaguetype } = useMyMatchStore();
  let currentYear = new Date().getFullYear();
  const launchYear = 2023;
  const years = [];
  for (let year = currentYear; year <= launchYear; year++) {
    years.push(year);
  }
  
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectnew, setSelectnew] = useState(true);
  const [activeTab, setActiveTab] = useState("Season");
  const [ismobile, setIsmobile] = useState(false);
  const [division, setDivision] = useState(true);
  const [CityName, setCityName] = useState(user?.city?.city_name);
  const [isMobileView, setIsMobileView] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [validImage, setvalidImage] = useState(false);
  const [profileimage, setprofileimage] = useState("");



  useEffect(() => {
    if (!authapproved) {
      navigate("/");
    }
    if (user?._id != id) {
      navigate("/");
    }
  }, [authapproved]);
  useEffect(() => {
    // Update isMobileView based on the viewport width
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 767); // Adjust the breakpoint as needed for mobile view
    };

    // Call the handleResize function on initial load and window resize
    handleResize();
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const {
    matchid,
    grp_Id,
    grpName,
    grpPlayerName,
    getpurpose,
    defaultMessageSent,
    opponent,
    grpPlayer,
    setDefaultMessageSent,
    GetgrpName,
  } = useMyMatchStore();

  useEffect(() => {
    getuserdetail(id);
    
    getseasondropdown();
    // gethomecourt();
    getcity();
    // getlevel();
    getstatedropdown();
    gethomecourtdropdown(user?.city?._id, id);
    // getid(id);
  }, []);
  useEffect(() => {
    gethistorydetail(id, SeasonYear);
  }, [SeasonYear]);
  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  const [selectedOption, setSelectedOption] = useState(
    user?.homeCourt?.displayName
  );

  useEffect(() => {
    setSelectedOption(user?.homeCourt);
  }, []);


  function getMaxDate() {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    const maxYear = today.getFullYear() - 18;
    const maxMonth = today.getMonth() + 1;
    const maxDay = today.getDate();
    return `${maxYear}-${maxMonth < 10 ? `0${maxMonth}` : maxMonth}-${maxDay < 10 ? `0${maxDay}` : maxDay
      }`;
  }

  const background = [img5];
  const random = Math.floor(Math.random() * background.length);
  const formData = new FormData();
  // const [selectedImage, setSelectedImage] = useState(null);


  formData.append("image", selectedImage);



  // ...

  useEffect(() => {
    const imageFile = watch('image');

    if (imageFile) {

      setSelectedImage(imageFile[0]);


    }
  }, selectedImage);


  const handleReset = () => {
    // Empty the value of the input field

    setValue('image', '');


  };

  const onSubmit = (data) => {
    // Handle form submission
    updateProfileImage(data)
    handleReset();

  };
const handleImageChange = (e) => {
  const selectedFile = e.target.files[0];
console.log("selectedFile",selectedFile);
  if (selectedFile) {
    const allowedTypes = ["image/png", "image/gif", "image/jpeg","image/webp"];
    if (!allowedTypes.includes(selectedFile.type)) {
      // Invalid file type, show toast error and reset the file input
      toast.error("Invalid file type. Please select an image file (PNG, GIF, JPEG).");
      e.target.value = null; // Reset the file input
      setvalidImage(false);
      setprofileimage("")
    } else {
      setprofileimage(selectedFile)
      setvalidImage(true);

    }
  }
};

  return (
    <>
      <div className="relative   py-5 h-full flex items-center z-1">
        <img
        alt=""
          className="absolute h-full z-0 top-0 left-0 w-full object-cover object-top brightness-50"
          src={background[random]}
        />
        <div className="w-full 2xl:px-28 lg:px-20 md:px-10 px-4  relative z-1">
          <div className="flex flex-wrap justify-center  " >
            <div className="2xl:w-[80%] border border-[#ffffff] rounded-lg lg:w-[80%] md:w-[90%] w-full 2xl:max-w-[90%]">
              <div className=" bg-[#6C6C6C] bg-opacity-40  rounded-lg lg:px-10 md:px-5 px-4 lg:py-10 md:py-5 py-4">
                <div className="md:grid md:grid-cols-3 items-center gap-4">
                  <form onSubmit={handleSubmit(onSubmit)} onKeyDown={onKeyDown} className="relative h-full w-full  ">
                    <label htmlFor="image">
                      <div className="relative cursor-pointer rounded-lg overflow-hidden w-full group">
                        <img className="w-full aspect-[1] object-cover" crossOrigin="anonymous" src={profileimage ? URL.createObjectURL(profileimage) :BASE_URL + `/image/${user?.image}`} alt="User Image" />
                        <div className="absolute inset-0 flex justify-center items-center opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                          <FaPencilAlt className="text-green" />
                        </div>
                      </div>
                    </label>


                    <input {...register("image", { onChange: (e) => handleImageChange(e) , required: true })} id="image" name="image" type="file"  accept="image/png, image/gif, image/jpeg" className="hidden" />
                    {selectedImage && (
                      loader === false ?
                      <button disabled={!validImage} className="mt-11 absolute px-3 py-1 text-lg text-[#000] bg-green bottom-[15px] right-0 flex font-medium lg:text-[17px]  rounded-lg mx-auto" type="submit">
                        <span className="flex items-center" >Save <FaSave/></span>
                      </button>
                      :
                      <button type="button" className="font-medium bottom-[15px] right-0 lg:text-[25px] text-lg text-green rounded-lg mx-auto flex justify-center items-center">
                      <svg className="animate-spin mr-1 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Updating...
                    </button>
                    )}

                    {/* {loader && (
                      <button type="button" className="font-medium bottom-[15px] right-0 lg:text-[25px] text-lg text-green rounded-lg mx-auto flex justify-center items-center">
                        <svg className="animate-spin mr-1 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Updating...
                      </button>
                    )} */}
                  </form>
                  <div className="relative md:ml-16 col-span-2 md:mt-0 mt-8">
                    <h5 className=" text-[#fff] font-normal md:text-5xl text-3xl  relative uppercase font-nunito">
                      {user?.firstName} {user?.lastName}
                    </h5>
                    <p className="mt-5 text-[#fff] relative">Your Stats</p>
                    <div className="w-24 border-b-[4px] border-b-green "></div>

                    <div className="flex overflow-auto ">
                      <div className="box-border rounded-sm flex flex-col mt-5 justify-center items-center  border-[#ffffff] bg-[#fff] h-16 w-20 xl:p-3 border-4 ">
                        <p className="  text-[#7E7777] font-medium md:text-2xl text-xl  relative">
                          {myDetails?.total_leagues ?? "0"}
                        </p>
                        <p className="text-[13px] text-[#7E7777] font-light  ">Leagues</p>
                      </div>
                      <div className="box-border rounded-sm ml-3 flex flex-col mt-5 justify-center items-center  border-[#39FD13] bg-[#39FD13] h-16 w-20 xl:p-3 border-4 ">
                        <p className=" text-[#7E7777] font-medium md:text-2xl text-xl  relative">
                          {myDetails?.total_matches ?? "0"}
                        </p>
                        <p className=" text-[13px] text-[#7E7777] font-light">Matches</p>
                      </div>
                      <div className="box-border rounded-sm ml-3 flex flex-col mt-5 justify-center items-center  border-[#ffffff] bg-[#fff] h-16 w-20 xl:p-3 border-4 ">
                        <p className=" text-[#7E7777] font-medium md:text-2xl text-xl  relative">
                          {user?.level?.name?.toFixed(1) ?? "0"}
                        </p>
                        <p className="text-[13px] text-[#7E7777] font-light">Singles</p>
                      </div>
                      <div className="box-border rounded-sm ml-3 flex flex-col mt-5 justify-center items-center  border-[#ffffff] bg-[#fff] h-16 w-20 xl:p-3 border-4 ">
                        <p className=" text-[#7E7777] font-medium md:text-2xl text-xl  relative">
                          {user?.mixed_level?.name.toFixed(1) ?? "0"}
                        </p>
                        <p className="text-[13px] text-[#7E7777] font-light">Mixed</p>
                      </div>
                      <div className="box-border rounded-sm ml-3 flex flex-col mt-5 justify-center items-center  border-[#ffffff] bg-[#fff] h-16 w-20 xl:p-3 border-4 ">
                        <p className=" text-[#7E7777] font-medium md:text-2xl text-xl  relative">
                          {user?.doubles_level?.name.toFixed(1) ?? "0"}
                        </p>
                        <p className="text-[13px] text-[#7E7777] font-light">Doubles</p>
                      </div>
                    </div>

                    <div className=" mb-3">
                      <p className="flex whitespace-nowrap items-stretch  justify-between text-[#ffffff] font-medium mt-7 ">
                        Singles win
                        <span className="border-t self-end mb-2 w-full border-dashed"></span>
                        {myDetails?.singles_win ?? "0"}
                      </p>
                      <p className="flex whitespace-nowrap items-stretch  justify-between text-[#ffffff] font-medium mt-7 ">
                        Doubles win
                        <span className="border-t self-end mb-2 w-full border-dashed"></span>
                        {myDetails?.doubles_win ?? "0"}
                      </p>

                      <p className="flex whitespace-nowrap items-stretch  justify-between text-[#ffffff] font-medium mt-7 ">
                        Mixed win
                        <span className="border-t self-end mb-2 w-full border-dashed"></span>
                        {myDetails?.mixed_win ?? "0"}
                      </p>

                      {/* <p className="flex whitespace-nowrap items-stretch  justify-between text-[#ffffff] font-medium mt-7 ">
                      Current Ranking
                        <span className="border-t self-end mb-2 w-full border-dashed"></span>
                        {myDetails?.current_ranking ?? "0"}
                      </p> */}

                      {/* <p className="flex whitespace-nowrap items-stretch  justify-between text-[#ffffff] font-medium mt-7 ">
                      Career win/loss
                        <span className="border-t self-end mb-2 w-full border-dashed"></span>
                        {myDetails?.career_win_loss ?? "0"}
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap justify-center bg-[#161414]"  >
        <div className="2xl:w-[80%] lg:w-[85%] md:w-[90%] w-full 2xl:max-w-[90%] mt-10 mb-5  relative z-1">
          <div className="bg-[#91CC001A] pl-5 pr-5 pb-5 pt-5 rounded">
            <div className="">
              <button
                type="button"
                onClick={() => {
                  getdetail();
                  setSelectnew(true);
                }}
                className={`${selectnew
                  ? " bg-green text-[#000]"
                  : "text-[#fff]  bg-[#282B2D] "
                  } px-[29px] py-[10px] rounded-t mr-4`}
              >
                Profile
              </button>
              <button
                type="button"
                onClick={() => {
                  getformcheck();
                  setSelectnew(false);
                }}
                className={`${!selectnew
                  ? " bg-green text-[#000]"
                  : "text-[#fff]  bg-[#282B2D] "
                  } px-[29px] py-[10px] rounded-t `}
              >
                History
              </button>
            </div>
            {!selectnew ? (
              <div className=" bg-[#000] grid-cols-3 p-3 border-2 border-[#55ed5a]">
                {/* <div className=" flex items-center  justify-end   relative z-10">
                  <div
                    onClick={() => {
                      setDivision(true);
                      // setQutarFinal(false);
                      setActiveTab("Season");
                    }}
                    className={`box-border border-[1px]  cursor-pointer flex flex-col mt-5 border-r border-[gray] justify-center items-center  bg-[#fff] h-10 w-[6rem] text-[12px] xl:p-[.30rem]  ${
                      activeTab === "Season" ? "bg-green" : ""
                    }`}
                  >
                    <p className=" text-[#7E7777] font-medium md:text-3xl text-2xl  relative"></p>
                    <p className=" text-[#7E7777] font-light ">Seasons</p>
                  </div>

                  <div
                    onClick={() => {
                      setDivision(false);
                      getMatchDetails(id);
                      setActiveTab("Matches");
                    }}
                    className={`box-border border-[1px]  cursor-pointer border-[gray]   flex flex-col mt-5  justify-center items-center  bg-[#fff] h-10 w-[6rem] text-[12px] xl:p-[.30rem]  ${
                      activeTab === "Matches" ? "bg-green" : ""
                    }`}
                  >
                    <button
                      onClick={() => {
                        getMatchDetails(id);
                      }}
                      className=" text-[#7E7777] font-light"
                    >
                      Matches
                    </button>
                  </div>
                </div> */}

                {division === true ? (
                  <label className="font-bold text-2xl text-[#fff]">Leagues History</label>
                ) : (
                  <label className="font-bold text-2xl">Match History</label>
                )}
                <div className="flex justify-end">
                  {division === true ? (
                    <div className="w-2/12">
                     <select
  value={SeasonYear}
  onChange={(e) => {
    getSeasonYear(e.target.value);
  }}
  name="Year"
  id="Year"
  className="bg-[#f1f1f1] mt-3 text-[#837575] w-full border border-[#7E7777] rounded-lg px-3 focus:outline-none"
>
  {/* Previous year (2024) */}
  <option value={new Date().getFullYear() - 1}>
    {new Date().getFullYear() - 1}
  </option>

  {/* Current year (2025) */}
  <option value={new Date().getFullYear()}>
    {new Date().getFullYear()}
  </option>

  {/* Next year (2026) */}
  <option value={new Date().getFullYear() + 1}>
    {new Date().getFullYear() + 1}
  </option>
</select>

                    </div>
                  ) : (
                    <div className="w-2/12">
                      <select
                        value={seasons}
                        onClick={() => {
                          getMatchDetails(id, seasons);
                        }}
                        onChange={(e) => {
                          getSeasons(e.target.value);
                        }}
                        name="Seasons"
                        id="Seasons"
                        // required
                        className=" bg-[#f1f1f1] mt-3 text-[#837575] w-full border border-[#7E7777] rounded-lg px-3 focus:outline-none"
                      >
                        <option value="">All Seasons</option>
                        {seasondropdown.map((data, index) => (
                          <option value={data?._id} key={index}>
                            {data?.name + "(" + data?.year + ")"}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>

                {division === true ? (
                  <div
                    id="style-1"
                    className=" bg-[#000] border-2 border-[#fff] rounded mt-3  h-[430px] "
                  >
                    <div className=" overflow-hidden">
                      <table className="w-full  text-[#fff]">
                        <thead>
                          <tr className="bg-green text-[#000]">
                            <th className=" py-3">#</th>
                            <th className="text-left py-3">League</th>
                            <th className="text-left py-3">Division</th>
                            <th className="text-left" >Level</th>
                            <th className="text-left whitespace-nowrap">Win %</th>
                            <th className="text-left whitespace-nowrap ">Loss %</th>
                          </tr>
                        </thead>
                        <tbody className="text-center  text-[#fff]">
                          {gethistory.map((data, index) => (
                            <tr className="hover:bg-[#39fd13] hover:bg-opacity-10" key={index}>
                              <td>{index + 1}</td>
                              <td className="text-left py-4 capitalize ">
                                <Link
                                  className="underline hover:text-green"
                                  to={`/mymatch/${data?.league?._id}`}
                                >
                                  {data?.league?.name
                                    ?.replace("(Female)", "")
                                    ?.replace("(Male)", "") ?? "N/A"}
                                </Link>
                              </td>
                              <td className="text-left ">{data?.division?.code ?? "N/A"}</td>
                              <td className="text-left ">{data?.level?.name?.toFixed(1) ?? "N/A"}</td>
                              <td className="text-left ">{Math.trunc(data?.win) ?? "0"}%</td>
                              <td className="text-left ">{Math.trunc(data?.loss) ?? "0"}%</td>
                            </tr>
                          ))}

                          {nolistHistory && (
                            <tr>
                              <td colSpan={6} className="py-20 ">
                                <p className=" text-center font-semibold text-3xl">
                                  You Have Not Played Any Leagues Yet.
                                </p>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    {/* <div className="text-[#3636364d] flex justify-center mt-40 text-3xl ">
                    You have not played any season yet
                  </div> */}
                  </div>
                ) : (
                  <div className=" bg-[#ffffff] border-2  border-[#fff] rounded mt-3  h-[430px] ">
                    <div
                      className="rounded-t-lg  h-[430px]  overflow-auto   "
                      id="style-1"
                    >
                      <table className="w-full relative   text-[#fff] ">
                        <thead className="sticky top-0">
                          <tr className="bg-[#282B2D] top-0  ">
                            <th className="py-5  px-5 ">#</th>
                            <th className="py-5">League</th>
                            <th className="md:py-5">Opponent</th>
                            <th className="md:py-5">Mate</th>
                            {/* {leaguetype != "singles" ? (
                              <th className="text-left">Partner</th>
                            ) : (
                              ""
                            )} */}
                            <th>Played On</th>
                            <th>Home Court</th>
                            <th>Result</th>
                            {/* <th>Ranking</th> */}
                          </tr>
                        </thead>

                        <tbody className="text-center text-[12px] text-[#000]">
                          {/* {nolistHistory && ( */}
                          {SeasonMatch.map((res, index) => (
                            <tr
                              key={index}
                              className="hover:bg-[#39fd13] hover:bg-opacity-5 "
                            >
                              <td>{index + 1}</td>
                              <td className="capitalize">
                                {res?.league?.name
                                  ?.replace("(female)", "")
                                  ?.replace("(male)", "") ?? "N/A"}
                              </td>
                              <td className="text-sm  py-4">
                                {res?.opponent.map((playername, index) => (
                                  <span
                                    title="View Profile"
                                    key={index}
                                    onClick={(e) => {
                                      // RedirectChanged(`/mymatch/${id}`, true);
                                      // navigate(
                                      //   `/playerdetail/${playername?._id}`
                                      // );
                                    }}
                                    className="capitalize cursor-auto "
                                  >
                                    {(index ? ", " : "") +
                                      playername?.firstName}{" "}
                                    {playername?.lastName}
                                  </span>
                                ))}
                                {/* {res.opponent.firstName} {res.opponent.lastName} */}
                              </td>
                              <td className="text-sm  py-4 px-3">
                                {res?.mate == undefined
                                  ? "-"
                                  : res?.mate?.firstName +
                                  " " +
                                  res?.mate?.lastName}

                                {/* {res.opponent.firstName} {res.opponent.lastName} */}
                              </td>
                              {/* {playername?._id != user?._id ? <>{playername.firstName} {playername.lastName},</>  :"" }  */}
                              {/* {leaguetype != "singles" ? ( */}
                              {/* <td>
                                  <span
                                    title="View Profile"
                                    className="capitalize cursor-pointer  hover:text-green"
                                    onClick={(e) => {
                                      navigate(
                                        `/playerdetail/${res?.mate?._id}`
                                      );
                                    }}
                                  >
                                    {res?.mate?.firstName} {res?.mate?.lastName}
                                  </span>
                                </td> */}
                              {/* ) : (
                                ""
                              )} */}
                              <td className=" py-4 flex justify-center">
                                {" "}
                                {res?.schedule_date !== null ? (
                                  <span className="flex" title="Schedule Dates">
                                    <Moment
                                      format={
                                        moment(res?.schedule_date)
                                          .format("MMM D")
                                          .includes("May")
                                          ? "MMM DD"
                                          : "MMM. DD"
                                      }
                                    >
                                      {res?.schedule_date}
                                    </Moment>
                                    <p className="px-1"> @ </p>
                                    {moment(res?.schedule_time).format(
                                      "hh:mm A"
                                    )}
                                  </span>
                                ) : (
                                  "N/A"
                                )}{" "}
                              </td>

                              <td className="py-4">{res?.home_court?.name}</td>

                              <td>
                                {" "}
                                <p className="text-[black] text md:text-sm text-xs">
                                  {res.match_result == "loss" ||
                                    res.match_result == "win" ? (
                                    <>
                                      <span className="border border-green  2xl:mx-2 mx-[1px] lg:p-1">
                                        {res?.team_first_score?.set_one}-
                                        {res?.team_second_score?.set_one}
                                      </span>
                                      <span className="border border-green  2xl:mx-2 mx-[1px] lg:p-1">
                                        {res?.team_first_score?.set_two}-
                                        {res?.team_second_score?.set_two}
                                      </span>
                                      {res?.team_first_score?.set_three ||
                                        res?.team_second_score?.set_three !=
                                        null ? (
                                        <span className="border border-green 2xl:mx-2 mx-[1px] lg:p-1">
                                          {res?.team_first_score?.set_three}-
                                          {res?.team_second_score?.set_three}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      -{" "}
                                      {res?.match_result === "loss" ? (
                                        <span className="text-[black]  2xl:mx-2 mx-[1px] lg:p-1">
                                          Lost
                                        </span>
                                      ) : (
                                        <span className="text-[black]  2xl:mx-2 mx-[1px] lg:p-1">
                                          Won
                                        </span>
                                      )}
                                    </>
                                  ) : res?.result === "score" ? (
                                    <>
                                      <span className="border border-green  2xl:mx-2 mx-[1px] lg:p-1">
                                        {res?.team_first_score?.set_one}-
                                        {res?.team_second_score?.set_one}
                                      </span>
                                      <span className="border border-green  2xl:mx-2 mx-[1px] lg:p-1">
                                        {res?.team_first_score?.set_two}-
                                        {res?.team_second_score?.set_two}
                                      </span>
                                      {res?.team_first_score?.set_three ||
                                        res?.team_second_score?.set_three !=
                                        null ? (
                                        <span className="border border-green 2xl:mx-2 mx-[1px] lg:p-1">
                                          {res?.team_first_score?.set_three}-
                                          {res?.team_second_score?.set_three}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  ) : res?.is_score_update === false ? (
                                    <span className="md:text-sm capitalize">
                                      -
                                    </span>
                                  ) : (
                                    <span className="md:text-sm capitalize">
                                      {res?.result === "Forfieted" ? (
                                        <span>
                                          Forfeited By {res?.forfiet_by}
                                        </span>
                                      ) : (
                                        <span>
                                          Withdrew By {res?.Withdraw_by}
                                        </span>
                                      )}
                                    </span>
                                  )}
                                </p>
                              </td>
                            </tr>
                          ))}
                          {/* )} */}
                          {noSeasonList && (
                            <tr>
                              <td colSpan={6} className="py-20 ">
                                <p className=" text-center font-semibold text-3xl">
                                  You Have Not Played Any Matches Yet.
                                </p>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    {/* <div className="text-[#3636364d] flex justify-center mt-40 text-3xl ">
                    You have not played any season yet
                  </div> */}
                  </div>
                )}
              </div>
            ) : (
              <form
                onSubmit={handleSubmit3(updateProfile)}
                onKeyDown={onKeyDown}
              >
                <div className=" bg-[#000] grid-cols-3 p-3  border-2 border-[#55ed5a] rounded-b-lg rounded-tr-lg">
                  <div className="grid gap-8 md:grid-cols-2 px-0 md:px-16 lg:px-16 mt-5">
                    <div className="col-span-1">
                      <label
                        htmlFor="firstName"
                        className=" text-green text-xs"
                      >
                        First Name 
                      </label>
                      <input
                        value={userlatest?.firstName ?? ""}
                        {...register3("firstName", { required: true })}
                        name="firstName"
                        id="firstName"
                        className=" bg-[#b1afaf] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none capitalize"
                        placeholder="First Name"
                        type="text"
                        readOnly
                        onChange={(e) => {
                          handleChange(e);
                          const capitalizedValue =
                            e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
                          e.target.value = capitalizedValue;
                        }}
                      />
                      {errors3.firstName && (
                        <span className=" text-[red] text-xs">
                          This field is required
                        </span>
                      )}
                    </div>
                    <div>
                      <label htmlFor="lastName" className=" text-green text-xs">
                        Last Name
                      </label>
                      <input
                        {...register3("lastName", { required: true })}
                        name="lastName"
                        autoComplete="LastName"
                        value={userlatest?.lastName ?? ""}
                        id="lastName"
                        className=" bg-[#b1afaf] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none capitalize"
                        placeholder="Last Name"
                        type="text"
                        readOnly
                        onChange={(e) => {
                          handleChange(e)
                          const capitalizedValue =
                            e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
                          e.target.value = capitalizedValue;
                        }}
                      />
                      {errors3.lastName && (
                        <span className=" text-[red] text-xs">
                          This field is required
                        </span>
                      )}
                    </div>

                    <div>
                      <label htmlFor="email" className=" text-green text-xs">
                        Email
                      </label>
                      <input
                        value={userlatest?.email ?? ""}
                        name="email"
                        autoComplete="email"
                        // required
                        className=" bg-[#b1afaf] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                        placeholder="Email"
                        id="email"
                        readOnly
                        disabled
                        type="email"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="Phonenumber"
                        className=" text-green text-xs"
                      >
                        Phone Number
                      </label>
                      <Input
                        name="mobile"
                        {...register3("mobile", { 
                          onChange: (e) => handleChange,
                          required: true,
                          minLength: ismobile ? 14 : 10,
                          })}
                        onChange={(e)=> setIsmobile(true)
                        }
                        // minLength={10}
                        maxLength={14}
                        value={userlatest?.mobile ? ("+1"+userlatest?.mobile) : ""}
                        id="mobile"
                        defaultCountry="US"
                        className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                      />
                      {userlatest?.mobile  && errors3?.mobile && errors3?.mobile.type == "minLength" && 
                      <span className=" text-[red] text-xs">
                          Mobile Number Should be of 10 digits.
                        </span>
                      }
                      {errors3?.mobile && errors3?.mobile.type !== "minLength" && (
                        <span className=" text-[red] text-xs">
                          This field is required
                        </span>
                      )}
                    </div>

                    <div>
                      <label htmlFor="dob" className=" text-green text-xs">
                        DOB
                      </label>
                      <input
                        // {...register3("dob", {
                        //   onChange: (e) => {
                        //     handleChange(e);
                        //     setdobuser(e.target.value);
                        //   },
                        //   required: true,
                        // })}
                        name="dob"
                        id="dob"
                        value={
                          userlatest?.dob
                            ? format(new Date(userlatest?.dob ?? ""), "yyyy-MM-dd")
                            : ""
                        }
                        autoComplete="dob"
                        placeholder="DOB"
                        // required
                        max={getMaxDate()}
                        className=" bg-[#b1afaf] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                        // placeholder="DOB"
                        readOnly
                        // disabled
                        type="date"
                      />
                      {errors3.dob && (
                        <span className=" text-[red] text-xs">
                          This field is required
                        </span>
                      )}
                      {dobusererr && (
                        <span className=" text-[red] text-xs">
                          Age Should Be 18+
                        </span>
                      )}
                    </div>

                    <div>
                      <label htmlFor="gender" className=" text-green text-xs">
                        Gender
                      </label>
                      <select
                        value={userlatest?.gender ?? ""}
                        disabled
                        readOnly
                        name="gender"
                        id="gender"
                        className="capitalize bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                      >
                        <option
                          value={userlatest?.gender}
                          className="hidden capitalize"
                        >
                          {userlatest?.gender}
                        </option>
                      </select>
                    </div>

                    <div>
                      <label
                        htmlFor="address_line1"
                        className=" text-green text-xs"
                      >
                        Street Address 1
                      </label>
                      <input
                        value={userlatest?.address_line1 ?? ""}
                        {...register3("address_line1", { required: true })}
                        onChange={(e) => handleChange(e)}
                        name="address_line1"
                        autoComplete="address_line1"
                        id="address_line1"
                        // required
                        className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                        placeholder="Street Address 1"
                        type="text"
                      />
                      {errors3.address_line1 && (
                        <span className=" text-[red] text-xs">
                          This field is required
                        </span>
                      )}
                    </div>

                    <div>
                      <label
                        htmlFor="address_line2"
                        className=" text-green text-xs"
                      >
                        Street Address 2
                      </label>
                      <input
                        {...register3("address_line2")}
                        value={userlatest?.address_line2}
                        onChange={(e) => handleChange(e)}
                        
                        name="address_line2"
                        autoComplete="address_line2"
                        id="address_line2"
                        // required
                        className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                        placeholder="Street Address 2"
                        type="text"
                      />
                      {/* {errors.address_line2 && (
                      <span className=" text-[#ffef00] text-xs">
                        This field is required
                      </span>
                    )} */}
                    </div>

                    <div>
                      <label
                        htmlFor="address_city"
                        className=" text-green text-xs"
                      >
                        City
                      </label>
                      <input
                      {...register3("address_city", { required: true })}
                        value={userlatest?.address_city ?? ""}
                        onChange={(e) => handleChange(e)}
                        
                        name="address_city"
                        autoComplete="address_city"
                        id="address_city"
                        // required
                        className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                        placeholder="City"
                        type="text"
                      />
                      {errors3.address_city && (
                        <span className=" text-[red] text-xs">
                          This field is required
                        </span>
                      )}
                    </div>

                    <div>
                      <label
                        htmlFor="address_state"
                        className=" text-green text-xs"
                      >
                        State
                      </label>
                      <select
                      {...register3("address_state", { required: true })}
                        value={userlatest?.address_state?._id ?? ""}
                        onChange={(e) => handleChange(e)}
                        
                        // onChange={(e) => {
                        //   gethomecourtdropdown(e.target.value);
                        // }}
                        name="address_state"
                        id="address_state"
                        // required
                        className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                      >
                        {/* <option
                          value={userlatest?.address_state?._id}
                          disabled
                          className="hidden"
                        >
                          {userlatest?.address_state?.name}
                        </option> */}
                        {statedrop.map((data, index) => (
                          <option value={data?._id} key={index}>
                            {data?.name}
                          </option>
                        ))}
                      </select>
                      
                      {errors3.address_state && (
                        <span className=" text-[red] text-xs">
                          This field is required
                        </span>
                      )}
                    </div>

                    
                    <div>
                      <label
                        htmlFor="address_zip"
                        className=" text-green text-xs"
                      >
                        Zipcode
                      </label>
                      <input
                          {...register3("address_zip", { 
                            onChange: (e) => handleChange,
                            required: true,
                            minLength: 5,
                            })}                        
                        name="address_zip"
                        autoComplete="address_zip"
                        id="address_zip"
                        maxLength={5}
                        // required
                        className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                        placeholder="Zipcode"
                        type="text"
                      />
                     {userlatest?.address_zip  && errors3?.address_zip && errors3?.address_zip.type == "minLength" && 
                      <span className=" text-[red] text-xs">
                          Zipcode Should be of 5 digits.
                        </span>
                      }
                      {errors3?.address_zip &&  errors3?.address_zip?.type !== "minLength" && (
                        <span className=" text-[red] text-xs">
                          This field is required
                        </span>
                      )}
                    </div>

                    <div>
                      <label
                        htmlFor="address_country"
                        className=" text-green text-xs"
                      >
                        Country
                      </label>
                      <select
                        value=""
                        {...register3("address_country", { required: true })}
                        // onChange={(e) => {
                        //   gethomecourtdropdown(e.target.value);
                        // }}
                        name="address_country"
                        id="address_country"
                        // required
                        className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                      >
                        <option value="USA">USA</option>
                      </select>

                      {errors3.address_country && (
                        <span className=" text-[red] text-xs">
                          This field is required
                        </span>
                      )}
                    </div>

                    {/* <div>
                      <label htmlFor="level" className=" text-green text-xs">
                        Skill Level
                      </label>
                      <div className="">
                        <input
                          value={user?.level?.name.toFixed(1) ?? ""}
                          readOnly
                          disabled */}
                    {/* {...register3("level", { required: true })} */}
                    {/* name="level"
                          id="level"
                          // required
                          className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none "
                        /> */}
                    {/* <option value={user?.level?._id} className="hidden capitalize">
                              {user?.level?.name}
                          </option>

                          {levels?.map((data, index) => (
                            <option value={data?._id} key={index}>
                              {data?.name}
                            </option>
                          ))}
                        </select> */}
                    {/* {errors3.level && (
                            <span className=" text-[red] text-xs">
                              This field is required
                            </span>
                          )} */}
                    {/* </div>
                    </div> */}

                    <div>
                      <label htmlFor="city" className=" text-green text-xs">
                        League City
                      </label>
                      <select
                       
                        {...register3("city", {
                          
                          onChange: (e) => {gethomecourtdropdown(e.target.value, userlatest?._id);handleChange(e)},
                          required: true,
                        })}
                        value={userlatest?.city?._id ?? ""}
                        onClick={(e)=>{
                          const selectedOption = e.target.options[e.target.selectedIndex];
                          const content = selectedOption?.getAttribute("content");
                          setCityName(content);
                          setSelectedOption(null);
                        }}
                        name="city"
                        id="city"
                        className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                      >
                        {/* <option
                          value={userlatest?.city?._id}
                          disabled
                          className="hidden"
                          
                        >
                          {userlatest?.city?.city_name}
                        </option> */}
                        {cityDropdown.map((data, index) => (
                          <option
                            value={data?._id}
                            content={data?.city_name}

                            key={index}
                            className="capitalize"
                          >
                            {data?.city_name}
                          </option>
                        ))}
                      </select>
                      {errors3.city && (
                        <span className=" text-[red] text-xs">
                          This field is required
                        </span>
                      )}
                    </div>

                    <div>
                      <label
                        htmlFor="homeCourt"
                        className=" text-green text-xs"
                      >
                        Home Court In {CityName === null ? userlatest?.city?.city_name : CityName }
                      </label>
                      {/* <select
                        value={selectedOption ?? ""}
                        // value={user?.homeCourt?._id ?? ""}
                        {...register3("homeCourt", {
                          onChange: (e) => setSelectedOption(e.target.value),
                          required: true,
                        })}
                        name="homeCourt"
                        id="homeCourt"
                        className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                      >
                        <option value="" className="hidden capitalize">
                          Select Home Court
                        </option>

                        {homecourtdrop?.map((data, index) => (
                          <option
                            value={data?._id}
                            key={index}
                            className="capitalize"
                          >
                            {data?.name +" - "+data?.zipcode}
                          </option>
                        ))}
                      </select> */}
                      <Select
                            // required
                            // {...restLangField("homeCourt", { required: true })}
                          {...restHome}
                            name="homeCourt"
                            id="homeCourt"
                            options={ homecourtdrop}
                            placeholder="Type Your Home Court Name"
                            // onClick={() =>{ changeStep("1"); allClear()}}
                            // onClick={(e)=>{setIsHome("");getHomecourtid(""); getlocation("") }}
                            //  onChange={(e)=>{setIsHome(e);} }
                            //  onChange={option => homeCourtnameChange(option ? option.value : option)}
                            // {...register3("homeCourt", {
                            //   onChange: (e) => setSelectedOption(e),
                            //   required: true,
                            // })}
                            required
                            onChange={(e) => {
                              // handleChange(e);
                              homeCourtnameChange(e?._id);
                              setSelectedOption(e);
                            }}
                            getOptionLabel={(homecourtdrop) =>
                              homecourtdrop?.displayName + " - " + homecourtdrop?.zipcode
                            }
                            getOptionValue={(homecourtdrop) =>
                              homecourtdrop._id
                            }
                            // value={user?.homeCourt?._id}
                            // defaultInputValue={user?.homeCourt?.name}
                            // value={ishome}
                            // autoFocus={false}
                            // value={user.homeCourt.isVerified===true? user?.homeCourt: null}
                            // value={ishome}
                            value={  selectedOption}

                            // defaultInputValue={ishome?.name}
                            isSearchable={true}
                            // isClearable
                            isMulti={false}
                            className=" react-select-input"
                            // menuIsOpen={}
                            onMenuOpen={() => {
                              setSelectedOption(null);
                            }}
                          />
                          
                      {errors3.homeCourt && (
                        <span className=" text-[red] text-xs">
                          This field is required
                        </span>
                      )}
                      {selectedOption?.isVerified != undefined && selectedOption?.isVerified == false  &&
                          <span className="text-green text-xs">Your home court is still pending for approval.
                          </span>
                        }
                    </div>

                    {/* <div>
                      <label htmlFor="image" className=" text-green text-xs">
                        Profile Image
                      </label>
                      <input
                        {...register3("image", { required: false })}
                        value=""
                        id="image"
                        name="image"
                        type="file"
                        className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                      
                      />
                    </div> */}
                  </div>
                  <div className="text-center mt-7 mb-5">
                    {!loginLoading ? (
                      <button
                        className=" font-medium lg:text-[20px] text-lg text-[#000] bg-green px-11 py-2 rounded-lg mx-auto"
                        type="submit"
                      >
                        Update
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="font-medium lg:text-[20px] text-lg text-[#000] bg-green px-11 py-2 rounded-lg mx-auto flex justify-center items-center"
                      >
                        <svg
                          className="animate-spin mr-1 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        Updating...
                      </button>
                    )}
                    {/* <button
                      // onClick={(e) => {
                      // gettablehide();
                      // }}
                      className=" font-medium lg:text-[20px] text-lg text-[#000] bg-green px-11 py-2 rounded-lg mx-auto"
                      type="submit"
                    >
                      Update
                    </button> */}
                  </div>
           
                </div>
              
              </form>
            )}
            <div className="flex mt-3 justify-end" > 
            <div className="  mb-5">
                   
                   <button
                   onClick={()=>{setdeletepopup(true)}}
                     className=" font-medium lg:text-[15px] text-lg text-[#000] bg-[#dd1414] px-5 py-2 rounded-lg mx-auto"
                   >
                     Delete Account
                   </button>
               
                 {/* <button
                   // onClick={(e) => {
                   // gettablehide();
                   // }}
                   className=" font-medium lg:text-[20px] text-lg text-[#000] bg-green px-11 py-2 rounded-lg mx-auto"
                   type="submit"
                 >
                   Update
                 </button> */}
               </div>
               <svg
                          onClick={() => setIsOpen3(true)}
                          className=" ml-3 mt-2 cursor-pointer"
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          viewBox="0 0 43 43"
                          fill="none"
                        >
                          <path
                            d="M21.5 0.25C9.77 0.25 0.25 9.77 0.25 21.5C0.25 33.23 9.77 42.75 21.5 42.75C33.23 42.75 42.75 33.23 42.75 21.5C42.75 9.77 33.23 0.25 21.5 0.25ZM21.5 38.5C12.1287 38.5 4.5 30.8712 4.5 21.5C4.5 12.1287 12.1287 4.5 21.5 4.5C30.8712 4.5 38.5 12.1287 38.5 21.5C38.5 30.8712 30.8712 38.5 21.5 38.5ZM19.375 30H23.625V34.25H19.375V30ZM22.7962 8.835C18.4187 8.1975 14.5512 10.8962 13.3825 14.7637C13 15.9962 13.935 17.25 15.2312 17.25H15.6562C16.5275 17.25 17.2287 16.6337 17.5262 15.8262C18.2062 13.935 20.225 12.6387 22.4137 13.1062C24.4325 13.5312 25.92 15.5075 25.75 17.5687C25.5375 20.4162 22.3075 21.0325 20.5437 23.6887C20.5437 23.71 20.5225 23.71 20.5225 23.7312C20.5012 23.7737 20.48 23.795 20.4587 23.8375C20.2675 24.1562 20.0762 24.5175 19.9275 24.9C19.9062 24.9637 19.8637 25.0062 19.8425 25.07C19.8212 25.1125 19.8212 25.155 19.8 25.2187C19.545 25.9412 19.375 26.8125 19.375 27.875H23.625C23.625 26.9825 23.8587 26.2387 24.22 25.6012C24.2625 25.5375 24.2837 25.4737 24.3262 25.41C24.4962 25.1125 24.7087 24.8362 24.9212 24.5812C24.9425 24.56 24.9637 24.5175 24.985 24.4962C25.1975 24.2412 25.4312 24.0075 25.6862 23.7737C27.7262 21.84 30.4887 20.2675 29.915 16.2087C29.405 12.5112 26.4937 9.3875 22.7962 8.835Z"
                            fill="#39FD13"
                          />
                        </svg>
            </div>
      {/* popup of delete account */}
              
               <Popup
               open={deletepopup}
               closeOnDocumentClick={false}
               className=""
               contentStyle={{
                width: isMobileView ? "100%" : "50%",
                 background: "none",
                 border: "none",
               }}
               onClose={() => {
                 setdeletepopup(false);
               }}
             >
               <div className=" border-2 border-[white] px-1 w-3/4 m-auto bg-[#31343B] move overflow-auto max-h-[600px] rounded  relative">
                 <div className="float-right   ">
                   <button
                     className=" text-[#ffffff] text-2xl absolute font-poppins  md:leading-[2] top-[0px] right-[10px]"
                     // type="button"
                     onClick={() => {
                       // setopensubmit(false);
                       // setnewpopup(true)
                       setdeletepopup(false);
                       // getbuttondisable(false);
                     }}
                   >
                     X
                   </button>
                 </div>
                 <p className=" text-center text-[#fff] font-poppins  text-3xl md:text-2xl mt-16 my-2">
                 Are you sure?
                    <br />
                  </p>
                 <p className=" text-center text-[#fff] text-lg ">
                 Do you really want to delete your account with FLT?  
                   <br />
                 Please note this action cannot be reverted back.
                 </p>
                 <div className="flex justify-center text-lg  my-10 items-center">
                   <button
                     onClick={() => {
                       setdeletepopup(false);
                       // getbuttondisable(false);
                     }}
                     className="bg-[red] px-3 mx-5 rounded-md"
                   >
                     No
                   </button>

                   {!deleteload ? (
                     <button
                       // type="submit"
                       className="bg-green px-3 mx-5 rounded-md"
                       onClick={() => {
                         deleteUser();
                         gettablehide(false);
                         clearsearechlist();
                         getfirstname("");
                         getlastname("");
                         getcityid("");
                         grpPlayer("");
                         GetgrpName("");
                         setloginNoEmail(false);
                         setupcomingleauge(false);
                         getleaugesfalse(false);
                         changeStep("1");
                         allClear();
                         dropMessages();
                         setDefaultMessageSent(false);
                       }}
                     >
                       Yes
                     </button>
                   ) : (
                     <button
                       // type="submit"
                       disabled
                       className="flex items-center bg-green px-3 mx-5 rounded-md"
                       // onClick={() => {
                       //   PostMatchscore();
                       // }}
                     >
                       Yes..
                       <svg
                         className="animate-spin mr-1 h-5 w-5 text-white"
                         xmlns="http://www.w3.org/2000/svg"
                         fill="none"
                         viewBox="0 0 24 24"
                       >
                         <circle
                           className="opacity-25"
                           cx="12"
                           cy="12"
                           r="10"
                           stroke="currentColor"
                           strokeWidth="4"
                         ></circle>
                         <path
                           className="opacity-75"
                           fill="currentColor"
                           d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                         ></path>
                       </svg>
                     </button>
                   )}
                 </div>
               </div>
             </Popup>
             <Popup
        open={isOpen3}
        closeOnDocumentClick
        onClose={() => setIsOpen3(false)}
      >
        <div className="pt-10 px-4 overflow-auto max-h-[500px] bg-[#000] text-[#fff]">
          <div className="  text-center">
            <p className=" font-semibold 2xl:text-2xl text-2xl after-line relative">
             Delete Account
            </p>
            <div className="my-10">
              <p>
              This would remove your account and all match related data from FLT and this account becomes unaccessible.
            
              </p>
            </div>
          </div>
      
        </div>
      </Popup>
          </div>
        </div>
      </div>
    </>
  );
});
export default MyAccount;
